import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/header-simple.scss"

export default function HeaderSimple({ data }) {
  return (
    <div className="header-simple-wrapper">
      <div className="overlay"></div>
      <GatsbyImage image={data?.background?.localFile?.childImageSharp?.gatsbyImageData} alt={data?.background?.altText} />

      <div className="show-mobile">
        {data?.mobileBackground.localFile.childImageSharp.gatsbyImageData ? (
          <GatsbyImage image={data?.mobileBackground.localFile.childImageSharp.gatsbyImageData} alt={data?.mobileBackground?.altText} />
        ) : (
          <GatsbyImage image={data?.background.localFile.childImageSharp.gatsbyImageData} alt={data?.background?.altText} />
        )}
      </div>
      <h1>{data?.title}</h1>
    </div>
  )
}
