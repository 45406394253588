import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import HeaderSimple from "../components/blocks/HeaderSimple";

import "../styles/posts-list.scss"

export default function Archive({ data, pageContext, location }) {
  const heading = data?.wpPage?.blocks?.layouts
  const posts = data?.allWpPost?.nodes
  const currentPage = (pageContext.offset / 5) + 1

  return (
    <Fragment>
      <Seo
        title="News"
        // postSchema={JSON.parse(
        //   data.wp?.seo?.contentTypes?.post.schema.raw
        // )}
      />
      <Layout location={location}>
        {heading && (
          <HeaderSimple data={heading[0]} />
        )}

        <div className="row news-row">
          <div className="search-bar">
            <input type="search" placeholder="search news..." />
            <button>
              <StaticImage src="../images/search-icon.png" />
            </button>
          </div>

          <div className="posts-list-wrapper">
            {posts?.map(post => {
              return (
                <article className="post">
                  <div className="row">
                    <div className="col featured-image">
                      <Link to={post?.uri}>
                        <GatsbyImage image={post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData} alt={post?.altText} />
                      </Link>
                    </div>
                    <div className="col post-content">
                      <h2 className="title"><Link to={post?.uri}>{post?.title}</Link></h2>
                      <div className="date">{post?.date}</div>
                      <div className="description" dangerouslySetInnerHTML={{ __html: post?.excerpt}}></div>
                    </div>
                  </div>
                </article>
              )
            })}
            <div className="pagination ">
              <Link to={pageContext?.previousPagePath} className={"previous " + (currentPage === 1 ? 'inactive' : '')}></Link>
              {[...Array(pageContext?.totalPages)?.keys()]?.map(page => {
                const link = (page) ? `/news/${page + 1}` : '/news/'

                return (
                  <Link to={link}>{page + 1}</Link>
                )
              })}
              <Link to={pageContext?.nextPagePath} className={"next " + (currentPage === pageContext.totalPages ? 'inactive' : '')}></Link>
            </div>
          </div>
          <div className="sidebar-wrapper">
            <Sidebar />
          </div>
        </div>
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query($offset: Int!, $postsPerPage: Int!) {
    wpPage(slug: { eq: "news" }) {
      blocks {
        layouts {
          ... on WpPage_Blocks_Layouts_HeaderSimple {
            fieldGroupName
            title
            background {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            mobileBackground {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

// export async function config() {
//   return ({ params }) => {
//     return {
//       defer: true,
//     }
//   }
// }
